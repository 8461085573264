<template>
  <b-container
      class="bv-example-row mb-3"
      fluid
  >
    <hr v-if="!spinner">
    <b-spinner
        v-if="spinner"
        variant="primary"
        class="spinners"
    />
    <div v-if="!spinner">
      <b-card-actions
          class="cari-liste-card"
      >
        <b-row class="mt-50 mb-2">
          <div class="card-header-row" :style="{backgroundColor: $store.getters.cardTitle}">
            <b-col cols="12">
              <h4 class="text-white d-inline-block">Vardiya Kayıtları</h4>
            </b-col>
          </div>
        </b-row>
        <hr class="mt-3 mb-1">
        <b-row>
          <b-col cols="12">
            <table class="table table-sm table-striped table-hover" :class="width < 1000 ? 'table-responsive':''">
              <thead>
              <tr>
                <th scope="col"/>
                <th scope="col" :style="{backgroundColor:$store.getters.tableTitle || '#0ea9d7'}">
                  BAŞLANGIÇ
                </th>
                <th scope="col" :style="{backgroundColor:$store.getters.tableTitle || '#0ea9d7'}">
                  BİTİŞ
                </th>
                <th scope="col" :style="{backgroundColor:$store.getters.tableTitle || '#0ea9d7'}">
                  KONAKLAMA
                </th>
                <th scope="col" :style="{backgroundColor:$store.getters.tableTitle || '#0ea9d7'}">
                  ONLİNE SATIŞ
                </th>
                <th scope="col" :style="{backgroundColor:$store.getters.tableTitle || '#0ea9d7'}">
                  CİRO
                </th>
                <th scope="col" :style="{backgroundColor:$store.getters.tableTitle || '#0ea9d7'}">
                  NAKİT GELİR
                </th>
                <th scope="col" :style="{backgroundColor:$store.getters.tableTitle || '#0ea9d7'}">
                  KREDİ KARTI
                </th>
                <th scope="col" :style="{backgroundColor:$store.getters.tableTitle || '#0ea9d7'}">
                  KREDİLİ SATIŞ
                </th>
                <th scope="col" :style="{backgroundColor:$store.getters.tableTitle || '#0ea9d7'}">
                  TAHSİLATLAR
                </th>
                <th scope="col" :style="{backgroundColor:$store.getters.tableTitle || '#0ea9d7'}">
                  GİDERLER
                </th>
              </tr>
              </thead>
              <tbody>
              <tr
                  v-for="(shift, index) in salaryListItems"
                  :key="index"
                  @click="goVardiya(shift.vardiyaID)"
              >
                <td @click.stop>
                  <b-dropdown
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="bgDanger"
                      split-variant="bgInfo"
                      no-caret
                      size="sm"
                      class="btn-icon rounded-circle"
                      dropleft
                  >
                    <template #button-content>
                      <feather-icon
                          icon="AlignJustifyIcon"
                          class="text-white"
                      />
                    </template>
                    <b-dropdown-item
                        :baslangic="baslangic(shift.baslangic)"
                        :to="{ path: '/vardiyaOzeti/' + shift.vardiyaID }"
                    >
                      Vardiya Detayı
                    </b-dropdown-item>
                    <b-dropdown-item
                        :to="'/detayliGelirListesi/' + shift.vardiyaID"
                    >Gelir Kayıtları
                    </b-dropdown-item>
                    <b-dropdown-item
                        :to="'/giderKayitlari/' + shift.vardiyaID"
                    >Gider Kayıtları
                    </b-dropdown-item>
                    <b-dropdown-item
                        :to="'/konaklamaKayitlari/' + shift.vardiyaID"
                    >
                      Konaklama Kayıtları
                    </b-dropdown-item>
                  </b-dropdown>
                </td>
                <td>
                  {{ baslangic(shift.baslangic) }}
                </td>
                <td>
                  {{ baslangic(shift.bitis) }}
                </td>
                <td>
                  {{ shift.konukSayi }}
                </td>
                <td
                    :style="{
                      color: shift.onlineCiro == 0 ? '#d15151' : 'black',
                    }"
                >
                  {{ shift.onlineCiro }}
                  <strong>₺</strong>
                </td>
                <td :style="{ color: shift.ciro == 0 ? '#d15151' : 'black' }">
                  {{ shift.ciro }}
                  <strong>₺</strong>
                </td>
                <td
                    :style="{ color: shift.nakit == 0 ? '#d15151' : 'black' }"
                >
                  {{ shift.nakit }}
                  <strong>₺</strong>
                </td>
                <td
                    :style="{
                      color: shift.krediKarti == 0 ? '#d15151' : 'black',
                    }"
                >
                  {{ shift.krediKarti }}
                  <strong>₺</strong>
                </td>
                <td
                    :style="{ color: shift.kredi == 0 ? '#d15151' : 'black' }"
                >
                  {{ shift.kredi }}
                  <strong>₺</strong>
                </td>
                <td
                    :style="{
                      color: shift.tahsilat == 0 ? '#d15151' : 'black',
                    }"
                >
                  {{ shift.tahsilat }}
                  <strong>₺</strong>
                </td>
                <td
                    :style="{ color: shift.gider == 0 ? '#d15151' : 'black' }"
                >
                  {{ shift.gider }}
                  <strong>₺</strong>
                </td>
              </tr>
              </tbody>
            </table>
          </b-col>
        </b-row>
        <hr class="mt-2 mb-0">
        <b-row class="mt-2">
          <b-col cols="12">
            <p class="text-muted d-inline-block">
              Toplam Kayıt Sayısı {{ toplamKayit }}
            </p>
            <b-pagination
                v-model="currentPage"
                :total-rows="rows"
                first-number
                last-number
                prev-class="prev-item"
                next-class="next-item"
                class="float-right"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </b-card-actions>
    </div>
  </b-container>
</template>

<script>
import {
  BRow,
  BCol,
  BContainer,
  BTable,
  BBadge,
  BForm,
  BButton,
  BCard,
  BDropdown,
  BDropdownItem,
  BPagination,
  BSpinner,
} from 'bootstrap-vue'
import _ from 'lodash'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import { useWindowSize } from '@vueuse/core'

export default {
  components: {
    BCardActions,
    BRow,
    BCol,
    BContainer,
    BTable,
    BBadge,
    BForm,
    BButton,
    BCard,
    BDropdown,
    BDropdownItem,
    BPagination,
    BSpinner,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      spinner: true,
      currentPage: 1,
      rows: 200,
    }
  },
  computed: {
    salaryListItems() {
      return _.orderBy(
          this.$store.getters.vardiyaListesiReturn,
          'vardiyaID',
          'desc',
      )
    },
    toplamKayit() {
      return this.$store.getters.vardiyaListesiReturnKayit
    },
  },
  watch: {
    currentPage(newVal, oldVal) {
      this.$store.dispatch('vardiyaList', {
        sayfala: newVal || false,
      })
    },
  },
  beforeDestroy() {
    this.$store.commit('clearVardiyaList')
  },
  created() {
    this.$store
        .dispatch('vardiyaList', { sayfala: false })
        .then((res, position = localStorage.getItem('otelSuccessReturn')) => {
          this.spinner = false
          const mesaj = this.$store.getters.notificationSettings(res)
          if (mesaj) {
            this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Başarılı',
                    text: mesaj,
                    icon: 'ThumbsUpIcon',
                    variant: 'success',
                  },
                },
                { position },
            )
          }
        })
        .catch(err => {
          this.spinner = false
          const mesaj = this.$store.getters.notificationSettings(err)
          if (mesaj) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Hata!',
                text: mesaj,
                icon: 'ThumbsDownIcon',
                variant: 'danger',
              },
            })
          }
        })
  },
  methods: {
    goVardiya(id) {
      this.$router.push(`/vardiyaOzeti/${id}`)
    },
    baslangic(data) {
      return data.slice(0, 16)
    },
    bitis(data) {
      return data.slice(0, 16)
    },
    rezGo(rezNo) {
      this.$router.push(`/rezervasyon/${rezNo}`)
    },
  },
  setup() {
    const {
      width,
    } = useWindowSize()
    return {
      width,
    }
  },
}
</script>

<style scoped>
[dir] .table:not(.table-dark):not(.table-light) thead:not(.thead-dark) th,
[dir] .table:not(.table-dark):not(.table-light) tfoot:not(.thead-dark) th {
  padding: 3px !important;
  background-color: #31526f;
  color: white;
}

.myButton {
  color: white !important;
}

.myButton:hover {
  color: white !important;
}

a {
  color: #93bee1 !important;
}

a:hover {
  color: #6d62e4 !important;
}

.card-header {
  background-color: #2e83c9;
  height: 30px;
  position: relative;
}

.card-header h4 {
  position: absolute;
  color: white;
  margin-top: 10px;
}

.row {
  justify-content: center;
}

.table:not(.table-dark):not(.table-light) thead:not(.thead-dark) th,
.table:not(.table-dark):not(.table-light) tfoot:not(.thead-dark) th {
  background-color: #0ea9d7;
  color: white;
}

tr {
  text-align: center;
}

.toplamKayit {
  width: 20% !important;
}

.my-top-row-col-6 {
  display: inherit;
  align-items: end;
}
</style>
<style>
.card-body {
  padding: 10px 25px;
}
</style>
